* {
    box-sizing: border-box;
}

.VolunteerSignupPage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;
    background-color: #56332a;
}

.VolunteerDivider {
    color: #f4debd;
}

.VolunteerHeaderText {
    color: #f4debd;
    font-weight: 900;
    font-size: xx-large;
}

.VolunteerSubHeaderText {
    color: #f4debd;
    font-weight: 900;
    font-size: x-large;
}

.VolunteerSubSubHeaderText {
    color: #f4debd;
    font-weight: 500;
    font-size: x-large;
}

.VolunteerText {
    color: #f4debd;
    font-weight: 900;
}

.VolunteerInfo {
    color: #f4debd;
    font-weight: 400;
}

.VolunteerSignupButton {
    color: #56332a;
    background-color: #f4debd;
    border: none;
    border-radius: 20px;
    font-family: "Jost";
    font-weight: 900;
    font-size: 18px;
    min-width: 300px;
    min-height: 40px;
    margin-bottom: 30px;
}

.VolunteerSignupForm {
    max-width: 650px;
    margin: auto;
}

input[type=text].volInput {
    width: 100%;
    font-family: "Jost";
    font-size: large;
    color: #f4debd;
    background-color: transparent;
    border: none;
    border-bottom: 5px solid #f4debd;
}

input.volInput::placeholder {
    color: #f5e2c78f;
}

.VolunteerRadioLabel {
    color: #f4debd;
    padding-left: 15px;
}

.VolunteerCheckboxLabel {
    color: #f4debd;
    padding-left: 15px;
    font-weight: 400;
}

.checkboxes label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.centred {
    text-align: center;
}

.KundaRow {
    display: flex;
    padding-bottom: 20px;
    text-align: left;
    flex-wrap: wrap;
}

.KundaColLeft {
    flex-wrap: nowrap;
    float: left;
    width: 50%;
    padding-right: 7px;
}

.KundaColRight {
    flex-wrap: wrap;
    float: left;
    width: 50%;
    padding-left: 7px;
}

.KundaCol {
    width: 50%;
    .VolunteerText {
        text-align: center;
    }
}

.VolunteerOptionsContainer {
    max-width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 700px) {
    .KundaColLeft {
        margin-top: 20px;
        width: 100%;
        padding-right: 0px;
    };
    .KundaColRight {
        width: 100%;
        margin-top: 20px;
        padding-left: 0px;
    };

    .KundaCol {
        width: 100%;
    }
}
