.KundaMailArticlePage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;
    padding-bottom: 30px;
    background-color: #8b994e;
}

.KundaMailHeaderText {
    color: #002e07;
    font-weight: 900;
    font-size: xx-large;
}

.KundaMailSubHeaderText {
    color: #002e07;
    font-weight: 900;
    font-size: x-large;
}

.KundaMailNormalText {
    color: #002e07;
    font-weight: 600;
    font-size: normal;
}

.KundaMailParagraph {
    text-align: left;
}

.KundaMailParagraphImage {
    border: solid;
    border-color: #002e07;
    border-width: 6px;
    width: 600px;
}

.articleLink {
    text-decoration: underline;
}

.articleUnderline {
    text-decoration: underline;
}

@media screen and (max-width: 750px) {
    .KundaMailParagraphImage {
        max-width: 320px;
    }
}

// .KundaMainImage {
//     margin: 0;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     -ms-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);

//     max-width: 100%;
// }
