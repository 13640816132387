.AboutPage {
    min-height: 100vh;
    min-width: 100vw;

    height: auto;
    width: auto;

    background-color: #5797a9;
    text-align: left;
}

.AboutPageImage {
    // width: 100%;
    display: block;
    max-width: 400px;
    max-height: 400px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.AboutHeaderText {
    color: #ffa700;
    font-weight: 900;
    font-size: xx-large;
}

.AboutSubHeaderText {
    color: #ffa700;
    font-weight: 900;
    font-size: x-large;
}

.AboutText {
    color: #ffa700;
    font-weight: 900;
}

.normality {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.aboutTextContainer {
    display: inline-block;
    flex-wrap: nowrap;
    width: 60%;
    margin-top: 6px;
}

.aboutImageContainer {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    margin-top: 6px;
}

@media screen and (max-width: 800px) {
    .aboutTextContainer,
    .aboutImageContainer {
        width: 100%;
        margin-top: 0;
    };

    .AboutPageImage {
        margin-left: 20px;
        position: relative;
    }
}

@media screen and (max-width: 375px) {
    .AboutPageImage {
        margin-left: 0px;
        position: relative;
        max-width: 300px;
    }
}
