.VolunteerInfoPage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;

    background-color: #56332a;
}

.VolunteerDivider {
    color: #f4debd;
}

.VolunteerHeaderText {
    color: #f4debd;
    font-weight: 900;
    font-size: xx-large;
}

.VolunteerSubHeaderText {
    color: #f4debd;
    font-weight: 900;
    font-size: x-large;
}

.VolunteerSubSubHeaderText {
    color: #f4debd;
    font-weight: 500;
    font-size: x-large;
}

.VolunteerText {
    color: #f4debd;
    font-weight: 900;
}

.VolunteerInfo {
    color: #f4debd;
    font-weight: 400;
}

.VolunteerButton {
    color: #56332a;
    background-color: #f4debd;
    border: none;
    border-radius: 20px;
    font-family: "Jost";
    font-weight: 900;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 40px;
    min-width: 300px;
    min-height: 40px;
}