.NavBarImage {
    max-height: 90px;
}

.OpenMenuImage {
    max-height: 40px;
}

.MenuButton {
    font-family: "Jost";
    font-size: x-large;
    font-weight: 900;
    max-width: 240px;
    border: none;
    text-align: left;
    background-color: transparent;
    color: black;
}

.MenuSubButton {
    font-family: "Jost";
    font-size: large;
    font-weight: 900;
    max-width: 240px;
    border: none;
    text-align: left;
    padding-left: 15px;
    background-color: transparent;
    color: black;
}


.MenuButton:hover {
    text-decoration: none;
}

a {
    text-decoration: none;
    color: black;
}

.IconContainer {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ImageButton {
    border: none;
    background-color: transparent;
}

.IconImage {
    width: 20px;
    height: 20px;
}

.MuiMenu-root ul {
    // background-color: rgb(190, 149, 0);
    background-color: #f4debd;
    color: black;
    align-self: right;
}

.NavBarDownArrow {
    // margin-left: 20px;
    max-width: 35px;
    align-self: center;
}