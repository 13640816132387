.KundaMailPage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;

    background-color: #8b994e;
}

.KundaMailHeaderText {
    color: #002e07;
    font-weight: 900;
    font-size: xx-large;
}

.KundaMailNormalText {
    color: #002e07;
    font-weight: 900;
    font-size: larger;
}

.articleCoverImage {
    height: 350px;
    border: solid;
    border-width: 8px;
    border-color: #002e07;
}

.KundaRow {
    display: flex;
    padding-bottom: 20px;
    text-align: left;
    flex-wrap: wrap;
}

.KundaColLeft {
    flex-wrap: nowrap;
    float: left;
    width: 50%;
    padding-right: 7px;
}

.KundaColRight {
    flex-wrap: wrap;
    float: left;
    width: 50%;
    padding-left: 7px;
}

.centred {
    text-align: center;
}

@media screen and (max-width: 700px) {
    .KundaColLeft {
        margin-top: 20px;
        width: 100%;
        padding-right: 0px;
    };
    .KundaColRight {
        width: 100%;
        margin-top: 20px;
        padding-left: 0px;
    };

    .articleCoverImage {
        width: 100%;
    }
}

// .KundaMainImage {
//     margin: 0;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     -ms-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);

//     max-width: 100%;
// }