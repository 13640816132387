.HomePage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;

    background-color: #56332a;
}

.KundaMainImage {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    max-width: 650px;
}

.HomePageSubHeaderText {
    color: #f4debd;
    font-weight: 900;
    font-size: x-large;
}

@media screen and (max-width: 750px) {
    .KundaMainImage {
        max-width: 320px;
    }
}