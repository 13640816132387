.SubsidisedTicketPage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;

    background-color: #f4debd;
}

.SubsidisedTicketHeaderText {
    color: #431840;
    font-weight: 900;
    font-size: xx-large;
}

.SubsidisedTicketSubHeaderText {
    color: #431840;
    font-weight: 900;
    font-size: x-large;
}

.SubsidisedTicketText {
    color: #431840;
    font-weight: 900;
}

.SubsidisedFormField {
    margin-bottom: 0;
    margin-top: 25px;
}

.link {
    color: #431840;
    font-weight: 900;
    text-decoration: underline 2px;
}

.SubsidisedTicketAppForm {
    max-width: 650px;
    margin: auto;
}

input[type=text] {
    width: 100%;
    font-family: "Jost";
    font-size: medium;
    color: #431840;
    background-color: transparent;
    border: none;
    border-bottom: 5px solid #431840;
}

input[type=radio] {
    padding: 0;
    background-color: #f4debd;
 }

 input[type=radio]:checked {
    background-color: #431840;
 }

input::placeholder {
    color: #431840bc;
}

.KundaFormRow {
    margin-bottom: 12px;
    text-align: left;
}

.SubsidisedApplyButton {
    color: #f4debd;
    background-color: #431840;
    border: none;
    border-radius: 20px;
    font-family: "Jost";
    font-weight: 900;
    font-size: 18px;
    min-width: 300px;
    min-height: 40px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.subsidisedRadioLabel {
    padding-left: 15px;
    font-family: "Jost";
    font-size: medium;
    color: #431840;
}
