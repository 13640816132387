.ProgramPage {
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: auto;

    background-color: #f4debd;
}

.ProgramHeaderText {
    color: #431840;
    font-weight: 900;
    font-size: xx-large;
}

.ProgramSubHeaderText {
    color: #431840;
    font-weight: 900;
    font-size: x-large;
}

.ProgramText {
    color: #431840;
    font-weight: 900;
}

.link {
    color: #431840;
    font-weight: 900;
    text-decoration: underline 2px;
}

.KundaButton {
    color: #f4debd;
    background-color: #431840;
    border: none;
    border-radius: 20px;
    font-family: "Jost";
    font-weight: 900;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 40px;
    min-width: 300px;
    min-height: 40px;
}

.performer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
}

.performer-item {
    width: 48%;
    margin-bottom: 5px;
}

.performer-main {
    width: 100%;
}

// .performer-text-d {
//     color: #431840;
//     font-weight: 600;
//     font-style: bold;
//     margin-bottom: 5px;
// }

.performer-headliner-text {
    margin-top: 0;
    color: #431840;
    font-weight: 600;
    font-style: bold;
    margin-bottom: 5px;
    display: inline;
}

.performer-name-text {
    margin-top: 0;
    color: #431840;
    font-weight: 600;
    font-style: bold;
    margin-bottom: 5px;
    display: inline;
}

.performer-live-text {
    padding-left: 5px;
    color: #431840;
    vertical-align: super;
    font-weight: 300;
    font-style: italic;
    display: inline;
}

.performer-description-text {
    color: #431840;
    font-weight: 300;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 15px;
}

@media screen and (max-width: 700px) {
    .performer-item {
        width: 100%;
    }
    .performer-container {
        width: 100%;
        margin: 0;
    };
}
